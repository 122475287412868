<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">REFER SINH VIÊN</h6>
                  <b-button class="btn btn-light font-weight-bold btn-sm" @click="addSeeding()">Tạo mới</b-button>
                </div>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-6">
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Số điện thoại:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập số điện thoại..."
                          v-model="paramFilter.phone"
                      />
                    </div>
                    <div class="col-lg-2 ">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>

                    </div>

                  </div>

                </b-form>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >
                <template #cell(stt)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(sender_id)="data">
                  <span> SĐT : {{ data.item.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                  <span v-if="data.item.name">
                    họ tên: {{ data.item.name }}
                  </span>
                </template>
                <template #cell(link_refer)="data">
                  <a :href="data.item.link_refer">{{ data.item.link_refer }}</a>
                </template>
                <template #cell(total_refer)="data">
                  {{ data.item.total_refer }} / {{ data.item.max_refer }}
                </template>
                <template #cell(action)="data">
                  <b-button pill variant="info" size="sm" style="margin-right: 10px;" @click="editSeeding(data.item)">Sửa </b-button>
                </template>

                <template #cell(status)="data">
                  <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="data.item.status == 0"> pending </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="data.item.status == 1"> success </span>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="Form seeding" ref="modal-1">
      <div class="row">
        <div class="col-md-12">
          <b-form-group
              id="input-group-1"
              label="Số điện thoại(*):"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="form.phone"
                required
                placeholder="Số điện thoại"
                maxlength="100"
                :disabled="isEdit"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="input-group-2"
              label="Số lượt giới thiệu(*):"
              label-for="input-2"
          >
            <b-form-input
                id="input-2"
                v-model="form.num"
                required
                placeholder="Số lượt giới thiệu"
                maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="hideModal"> Cancel </b-button>
        <b-button size="sm" variant="success" @click="updateSeeding()">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const CampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        event_id: "",
        combo_voucher_id: "",
        date: '',
        limit: 20,
      },
      fieldsLog: [
        {key: "stt", label: "STT"},
        {key: "sender_id", label: "Thông tin người giới thiệu"},
        {key: "link_refer", label: "Link giới thiêu/code"},
        {key: "total_refer", label: "Lượt giới thiệu"},
        {key: "action", label: "Hành động"},
      ],
      events: [],
      combos: [],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      form: {
        phone: '',
        num: ''
      },
      isEdit: false,
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê seeding student", route: "/student-refer/report-seeding" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    hideModal() {
      this.$refs['modal-1'].hide()
    },
    addSeeding () {
      this.isEdit = false;
      this.form.num = '';
      this.form.phone = '';
      this.$refs['modal-1'].show();
    },
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportSeedingCampaignStudent(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data;
        this.paginate = body.data.meta;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    updateSeeding () {
      let params = this.form;
      CampaignRepository.addSeeding(params)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: response.data.message,
              });
              this.loadData();
              this.$refs['modal-1'].hide();
              this.isEdit = false;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    editSeeding (item) {
      this.form.num = item.max_refer;
      this.form.phone = item.phone;
      this.isEdit = true;
      this.$refs['modal-1'].show();
    }
  },
};
</script>

<style>
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
